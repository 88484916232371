var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-3 bg-light-gray questions text-center"},[(!_vm.saved)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('h2',{staticClass:"d-block w-100 text-center mb-3"},[_vm._v(" "+_vm._s(_vm.$t(_vm.currentStep.title))+" ")]),_vm._l((_vm.currentStep.questions),function(question){return _c('div',{key:question.id,staticClass:"col-12 mb-4 d-flex flex-column align-items-center"},[_c('div',{staticClass:"question"},[_c('h4',{staticClass:"font-weight-bold text-blue mb-3"},[_vm._v(" "+_vm._s(_vm.$t(question.title))+" "),(question.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()])]),(
            question.component == 'ThumbSwitchMultiSelect' ||
            question.component == 'MultiSelect'
          )?_c('div',{staticClass:"options mb-3"},[_c('div',{staticClass:"card w-100 p-3"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},_vm._l((question.options),function(option){return _c('div',{key:option.id,staticClass:"col-4"},[_c('button',{staticClass:"btn w-100 m-1",class:question.values.indexOf(option.id) > -1
                        ? 'btn-blue'
                        : 'btn-light-blue',attrs:{"value":option.id,"disabled":question.value === option.name},on:{"click":function($event){$event.preventDefault();return _vm.handleChange($event, {
                        name: question.title,
                        componentType: question.component,
                        groupId: _vm.currentStep.id,
                        id: question.id,
                        isChecked: false,
                        answered: true,
                        values: question.values,
                      })}}},[_vm._v(" "+_vm._s(_vm.$t(option.id))+" ")])])}),0)])])]):_vm._e(),(question.component == 'ThumbSwitch')?_c('div',{staticClass:"options text-center mb-3"},[_c('button',{staticClass:"btn px-4 mr-1",class:question.value ? 'btn-blue' : 'btn-light-blue',attrs:{"disabled":question.value ? true : false,"value":true},on:{"click":function($event){$event.preventDefault();return _vm.handleChange($event, {
                name: question.title,
                componentType: question.component,
                answered: true,
                groupId: _vm.currentStep.id,
                id: question.id,
                isChecked: false,
              })}}},[_vm._v(" SI ")]),_c('button',{staticClass:"btn px-4 ml-1",class:question.isChecked ? 'btn-blue' : 'btn-light-blue',attrs:{"disabled":question.isChecked ? true : false},on:{"click":function($event){$event.preventDefault();return _vm.handleChange($event, {
                name: question.title,
                componentType: question.component,
                answered: true,
                groupId: _vm.currentStep.id,
                id: question.id,
                isChecked: true,
              })}}},[_vm._v(" NO ")])]):_vm._e(),(question.component == 'ThumbSwitchSelect')?_c('div',{staticClass:"options mb-3"},[_c('div',{staticClass:"d-flex"},_vm._l((question.options),function(option){return _c('button',{key:option.id,staticClass:"btn mr-1 flex-fill",class:question.value === option.name ? 'btn-blue' : 'btn-light-blue',attrs:{"value":option.name,"disabled":question.value === option.name},on:{"click":function($event){$event.preventDefault();return _vm.handleChange($event, {
                  name: question.title,
                  componentType: question.component,
                  groupId: _vm.currentStep.id,
                  id: question.id,
                  isChecked: false,
                  answered: true,
                })}}},[_vm._v(" "+_vm._s(_vm.$t(option.name))+" ")])}),0)]):_vm._e(),(question.component == 'Text')?_c('div',{staticClass:"options mb-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","name":question.name},domProps:{"value":question.value},on:{"keyup":function($event){return _vm.handleChange($event, {
                name: question.title,
                componentType: question.component,
                groupId: _vm.currentStep.id,
                id: question.id,
                isChecked: false,
                answered: true,
              })}}})]):_vm._e(),(question.component == 'Select')?_c('div',{staticClass:"options mb-3"},_vm._l((question.options),function(option){return _c('button',{key:option.id,staticClass:"btn mr-1 flex-fill",class:question.value === option.name ? 'btn-blue' : 'btn-light-blue',attrs:{"value":option.name,"disabled":question.value === option.name},on:{"click":function($event){$event.preventDefault();return _vm.handleChange($event, {
                name: question.title,
                componentType: question.component,
                groupId: _vm.currentStep.id,
                id: question.id,
                isChecked: false,
                answered: true,
              })}}},[_vm._v(" "+_vm._s(_vm.$t(option.name))+" ")])}),0):_vm._e()])})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[(!_vm.currentStep.isLast)?_c('button',{staticClass:"btn btn-lg",class:!_vm.currentStep.isComplete ? 'btn-gray' : 'btn-primary',attrs:{"disabled":!_vm.currentStep.isComplete},on:{"click":function($event){$event.preventDefault();return _vm.handleOnNextStep()}}},[_vm._v(" Continuar ")]):_c('button',{staticClass:"btn btn-lg",class:!_vm.currentStep.isComplete ? 'btn-gray' : 'btn-primary',attrs:{"disabled":!_vm.isComplete || _vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.handleOnSave()}}},[_vm._v(" Guardar ")])])])]):_c('div',{staticClass:"container"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-12 my-5"},[_c('h1',{staticClass:"d-block font-weight-bold mb-3"},[_vm._v(" ¡Gracias, "),_c('span',{staticClass:"text-blue"},[_vm._v(_vm._s(_vm.email))]),_vm._v("! ")]),_c('h4',{staticClass:"d-block my-3"},[_vm._v(" En breve un asesor te contactará para terminar tu venta online. Recuerda tener a mano los documentos de tu auto. ")]),_c('small',[_vm._v(" Tu id de inspección es: "),(_vm.bookingId)?_c('strong',{staticClass:"text-blue"},[_vm._v(_vm._s(_vm.bookingId))]):_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }