<template>
  <section v-if="!saved" class="pt-3 bg-light-gray steps">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <p
            v-for="(question, i) in questions"
            :key="question.id"
            class="step mx-2"
            :class="{
              active: i === currentPosition,
              checked: question.isComplete,
            }"
          >
            <img
              v-if="question.isComplete"
              src="@/assets/icons/icon_step_checked.svg"
              alt="Done"
            />
            <span v-else>{{ i + 1 }}</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    saved: {
      get() {
        return this.$store.state.inspection.saved;
      },
    },
    questions: {
      get() {
        return this.$store.state.inspection.questions;
      },
    },
    steps: {
      get() {
        return this.$store.state.inspection.steps;
      },
    },
    currentPosition: {
      get() {
        return this.$store.state.inspection.currentPosition;
      },
    },
  },
};
</script>
