<template>
  <section class="pb-3 bg-light-gray questions text-center">
    <div v-if="!saved" class="container">
      <div class="row">
        <h2 class="d-block w-100 text-center mb-3">
          {{ $t(currentStep.title) }}
        </h2>
        <!-- <div class="card">
          {{ questions }}
        </div> -->
        <div
          v-for="question in currentStep.questions"
          :key="question.id"
          class="col-12 mb-4 d-flex flex-column align-items-center"
        >
          <div class="question">
            <h4 class="font-weight-bold text-blue mb-3">
              {{ $t(question.title) }}
              <small v-if="question.required" class="text-danger">*</small>
            </h4>
          </div>

          <div
            v-if="
              question.component == 'ThumbSwitchMultiSelect' ||
              question.component == 'MultiSelect'
            "
            class="options mb-3"
          >
            <div class="card w-100 p-3">
              <div class="container-fluid">
                <div class="row">
                  <div
                    class="col-4"
                    v-for="option in question.options"
                    :key="option.id"
                  >
                    <button
                      :value="option.id"
                      class="btn w-100 m-1"
                      :class="
                        question.values.indexOf(option.id) > -1
                          ? 'btn-blue'
                          : 'btn-light-blue'
                      "
                      :disabled="question.value === option.name"
                      @click.prevent="
                        handleChange($event, {
                          name: question.title,
                          componentType: question.component,
                          groupId: currentStep.id,
                          id: question.id,
                          isChecked: false,
                          answered: true,
                          values: question.values,
                        })
                      "
                    >
                      {{ $t(option.id) }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="question.component == 'ThumbSwitch'"
            class="options text-center mb-3"
          >
            <button
              class="btn px-4 mr-1"
              :class="question.value ? 'btn-blue' : 'btn-light-blue'"
              :disabled="question.value ? true : false"
              :value="true"
              @click.prevent="
                handleChange($event, {
                  name: question.title,
                  componentType: question.component,
                  answered: true,
                  groupId: currentStep.id,
                  id: question.id,
                  isChecked: false,
                })
              "
            >
              SI
            </button>
            <button
              class="btn px-4 ml-1"
              :class="question.isChecked ? 'btn-blue' : 'btn-light-blue'"
              :disabled="question.isChecked ? true : false"
              @click.prevent="
                handleChange($event, {
                  name: question.title,
                  componentType: question.component,
                  answered: true,
                  groupId: currentStep.id,
                  id: question.id,
                  isChecked: true,
                })
              "
            >
              NO
            </button>
          </div>

          <div
            v-if="question.component == 'ThumbSwitchSelect'"
            class="options mb-3"
          >
            <div class="d-flex">
              <button
                v-for="option in question.options"
                :key="option.id"
                :value="option.name"
                class="btn mr-1 flex-fill"
                :class="
                  question.value === option.name ? 'btn-blue' : 'btn-light-blue'
                "
                :disabled="question.value === option.name"
                @click.prevent="
                  handleChange($event, {
                    name: question.title,
                    componentType: question.component,
                    groupId: currentStep.id,
                    id: question.id,
                    isChecked: false,
                    answered: true,
                  })
                "
              >
                {{ $t(option.name) }}
              </button>
            </div>
          </div>

          <div v-if="question.component == 'Text'" class="options mb-3">
            <input
              type="text"
              class="form-control"
              :name="question.name"
              :value="question.value"
              @keyup="
                handleChange($event, {
                  name: question.title,
                  componentType: question.component,
                  groupId: currentStep.id,
                  id: question.id,
                  isChecked: false,
                  answered: true,
                })
              "
            />
          </div>
          <div v-if="question.component == 'Select'" class="options mb-3">
            <button
              v-for="option in question.options"
              :key="option.id"
              :value="option.name"
              class="btn mr-1 flex-fill"
              :class="
                question.value === option.name ? 'btn-blue' : 'btn-light-blue'
              "
              :disabled="question.value === option.name"
              @click.prevent="
                handleChange($event, {
                  name: question.title,
                  componentType: question.component,
                  groupId: currentStep.id,
                  id: question.id,
                  isChecked: false,
                  answered: true,
                })
              "
            >
              {{ $t(option.name) }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button
            v-if="!currentStep.isLast"
            class="btn btn-lg"
            :class="!currentStep.isComplete ? 'btn-gray' : 'btn-primary'"
            :disabled="!currentStep.isComplete"
            @click.prevent="handleOnNextStep()"
          >
            Continuar
          </button>
          <button
            v-else
            class="btn btn-lg"
            :class="!currentStep.isComplete ? 'btn-gray' : 'btn-primary'"
            :disabled="!isComplete || loading"
            @click.prevent="handleOnSave()"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row text-center">
        <div class="col-12 my-5">
          <h1 class="d-block font-weight-bold mb-3">
            ¡Gracias, <span class="text-blue">{{ email }}</span
            >!
          </h1>
          <h4 class="d-block my-3">
            En breve un asesor te contactará para terminar tu venta online.
            Recuerda tener a mano los documentos de tu auto.
          </h4>
          <small>
            Tu id de inspección es:
            <strong class="text-blue" v-if="bookingId">{{ bookingId }}</strong>
            <div class="spinner-border text-primary" role="status" v-else>
              <span class="sr-only">Loading...</span>
            </div>
          </small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  computed: {
    loading: {
      get() {
        return this.$store.state.inspection.loading;
      },
    },
    leadId: {
      get() {
        return this.$store.state.quote.leadId;
      },
    },
    newForm: {
      get() {
        return this.$store.state.quote.newForm;
      },
    },
    maker: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "maker"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    year: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "year"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    model: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "model"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    milage: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "milage"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    version: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "version"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    email: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "email"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    phone: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "phone"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    saved: {
      get() {
        return this.$store.state.inspection.saved;
      },
    },
    startTime: {
      get() {
        return this.$store.state.inspection.startTime;
      },
    },
    bookingId: {
      get() {
        return this.$store.state.inspection.bookingId;
      },
    },
    draftId: {
      get() {
        return this.$store.state.inspection.draftId;
      },
    },
    isComplete: {
      get() {
        return this.$store.state.inspection.isComplete;
      },
    },
    report: {
      get() {
        return this.$store.state.inspection.report;
      },
    },
    currentStep: {
      get() {
        return this.$store.state.inspection.currentStep;
      },
    },
    currentPosition: {
      get() {
        return this.$store.state.inspection.currentPosition;
      },
    },
    steps: {
      get() {
        return this.$store.state.inspection.steps;
      },
    },
    questions: {
      get() {
        return this.$store.state.inspection.questions;
      },
    },
    quoteRange: {
      get() {
        return this.$store.state.quote.quoteRange;
      },
    },
  },
  updated() {
    this.handleRequired();
    this.handleOnComplete();
  },

  methods: {
    ...mapActions({
      UPDATE_QUESTIONS: "inspection/UPDATE_QUESTIONS",
      UPDATE_STEP_POSITION: "inspection/UPDATE_STEP_POSITION",
      SAVE_DRAFT: "inspection/SAVE_DRAFT",
      SAVE_INSPECTION: "inspection/SAVE_INSPECTION",
      UPDATE_COMPLETE_STEP: "inspection/UPDATE_COMPLETE_STEP",
      UPDATE_COMPLETE_ALL: "inspection/UPDATE_COMPLETE_ALL",
    }),
    handleRequired() {
      const isComplete = this.currentStep.questions.every(
        (q) => q.isAnswered && q.required
      );

      const groupId = this.currentStep.id;

      this.UPDATE_COMPLETE_STEP({
        isComplete,
        groupId,
      });
    },
    handleOnSave() {
      this.SAVE_INSPECTION({
        quoteData: {
          maker: this.maker,
          year: this.year,
          model: this.model,
          milage: this.milage,
          version: this.version,
          minPrice: this.quoteRange.min,
          maxPrice: this.quoteRange.max,
          email: this.email,
          phone: this.phone,
        },
        startTime: this.startTime,
        leadId: this.leadId,
        report: this.report,
        draftId: this.draftId,
      });
    },
    handleOnComplete() {
      const isComplete = this.questions.every((g) => g.isComplete);

      this.questions.forEach((g) => {
        if (g.id === "blockingQuestions") {
          g.questions.forEach((q) => {
            if (q.isBlocker && q.value) {
              window.dataLayer.push({
                event: "gaEvent",
                eventCategory: "evo-evadis",
                eventAction: "click",
                eventLabel: q.title + " " + q.value,
              });

              // return this.$router.push({ path: "/error" });
            }
          });
        }
      });

      this.UPDATE_COMPLETE_ALL(isComplete);
    },
    handleOnNextStep() {
      window.scrollTo(0, 0);
      const nextStep = this.currentPosition + 1;

      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "evo-evadis",
        eventAction: "click",
        eventLabel: "step: " + this.currentPosition,
      });

      this.UPDATE_STEP_POSITION({
        questions: this.questions,
        nextStep,
      });
    },
    handleToggle(component) {
      this.UPDATE_QUESTIONS(component);
    },
    handleChange(event, component) {
      const {
        name,
        groupId,
        id,
        componentType,
        isChecked,
        answered,
        values,
      } = component;
      let value;

      switch (componentType) {
        case "ThumbSwitch":
          {
            value = event.target.value ? true : false;
          }
          break;
        case "MultiSelect":
        case "ThumbSwitchMultiSelect":
          {
            // const optionIndex = values.findIndex(
            //   (option) => option === event.target.value
            //   // console.log(option, event.target.value)
            // );
            let newValues = values;
            const index = newValues.indexOf(event.target.value);

            if (index > -1) {
              newValues.splice(index, 1);
            } else {
              newValues.push(event.target.value);
            }
            // console.log(optionIndex);

            value = newValues;
            // console.log(values, value);
          }
          break;
        default: {
          value = event.target.value ? event.target.value : false;
        }
      }

      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "evo-evadis",
        eventAction: "click",
        eventLabel: name + ": " + value,
      });

      this.UPDATE_QUESTIONS({ id, groupId, value, isChecked, answered });

      this.SAVE_DRAFT({
        quoteData: {
          maker: this.maker,
          year: this.year,
          model: this.model,
          milage: this.milage,
          version: this.version,
          user: this.email,
        },
        startTime: this.startTime,
        draftId: this.draftId,
        leadId: this.leadId,
        report: this.report,
      });
    },
  },
};
</script>
