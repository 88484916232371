<template>
  <main>
    <section class="bg-cyan py-3">
      <h2
        class="text-uppercase font-weight-bold d-block text-center m-0 text-uppercase"
      >
        {{ $t("self-inspection.title") }}
      </h2>
    </section>
    <!-- <section class="py-3">
      <h4 class="d-block text-center font-weight-bold">
        
      </h4>
    </section> -->
    <!-- <b-overlay :show="loading"> -->
    <Steps :questions="questions" />
    <Questions :questions="questions" />
    <!-- </b-overlay> -->
  </main>
</template>

<script>
import { mapActions } from "vuex";
import Questions from "../components/Questions";
import Steps from "../components/Steps";

export default {
  components: {
    Questions,
    Steps,
  },
  computed: {
    questions: {
      get() {
        return this.$store.state.inspection.questions;
      },
    },
    loading: {
      get() {
        return this.$store.state.inspection.loading;
      },
    },
  },
  mounted() {
    this.GET_QUESTIONS();
  },
  methods: {
    ...mapActions({
      GET_QUESTIONS: "inspection/GET_QUESTIONS",
    }),
  },
};
</script>
